import { type CheckOfflineMetadata, offlineDatabase } from '@/offlineDatabase'

export const OfflineDatabaseService = () => {
  const getCheckByCheckId = (checkId: string): Promise<CheckOfflineMetadata | undefined> => {
    return offlineDatabase.checkOfflineMetadata.where('checkId').equals(checkId).first()
  }

  const getCheckByGuid = (checkGuid: string): Promise<CheckOfflineMetadata | undefined> => {
    return offlineDatabase.checkOfflineMetadata.where('guid').equals(checkGuid).first()
  }

  const getLastCompletedTaskId = async (checkId: string): Promise<string | undefined> => {
    if (!checkId) {
      return undefined
    }

    const check = await getCheckByCheckId(checkId)

    if (!check) {
      return undefined
    }

    const lastCompletedTask = check.check.checkTasks.findLast((c) => c.isCompleted)

    if (!lastCompletedTask) {
      return undefined
    }

    return lastCompletedTask.id
  }

  const getLastCompletedTaskIdByCheckOfflineGuid = async (
    checkOfflineGuid?: string
  ): Promise<string | undefined> => {
    if (!checkOfflineGuid) {
      return undefined
    }

    const check = await getCheckByGuid(checkOfflineGuid)

    if (!check) {
      return undefined
    }

    const lastCompletedTask = check.check.checkTasks.findLast((c) => c.isCompleted)

    if (!lastCompletedTask) {
      return undefined
    }

    return lastCompletedTask.id
  }

  const getAllChecks = async (): Promise<CheckOfflineMetadata[]> => {
    return offlineDatabase.checkOfflineMetadata.toArray()
  }

  const clearAllChecks = () => {
    offlineDatabase.checkOfflineMetadata.clear()
  }

  const deleteCheck = (id: string): void => {
    offlineDatabase.checkOfflineMetadata.delete(id)
  }

  return {
    getCheckByCheckId,
    getCheckByGuid,
    getLastCompletedTaskId,
    getLastCompletedTaskIdByCheckOfflineGuid,
    getAllChecks,
    clearAllChecks,
    deleteCheck
  }
}
