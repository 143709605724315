<script lang="ts" setup>
import { ref } from 'vue'
import { UserService } from '@/services/UserService'
import type { ResetPasswordResponse } from '@/interfaces/ResetPasswordResponse'
import { ElNotification } from 'element-plus'
import { useRouter } from 'vue-router'

const router = useRouter()

const password = ref('')
const email = ref('')
const verificationCode = ref('')
let userService = new UserService()

const resetPassword = async (): Promise<void> => {
  let resetPasswordResponse: ResetPasswordResponse = await userService.resetPassword({
    email: email.value,
    password: password.value,
    token: verificationCode.value
  })

  if (!resetPasswordResponse.success) {
    ElNotification({
      title: 'Failure',
      message: resetPasswordResponse.error,
      type: 'error',
      customClass: 'notification'
    })

    return
  }

  ElNotification({
    title: 'Success',
    message: 'Password has been changed',
    type: 'success',
    customClass: 'notification'
  })

  await router.push('/')
}

const sendVerificationCode = async (): Promise<void> => {
  await userService.sendVerificationCode(email.value)

  ElNotification({
    title: 'Success',
    message: 'Verification code has been sent',
    type: 'success',
    customClass: 'notification'
  })
}
</script>

<template>
  <div class="container">
    <el-text style="font-size: 30px; color: white">Reset your password:</el-text>
    <el-input v-model="email" placeholder="email" size="large" />

    <el-button size="large" @click="sendVerificationCode">Send verification code</el-button>

    <el-input v-model="verificationCode" placeholder="verification code" size="large" />
    <el-input
      v-model="password"
      placeholder="new password"
      show-password
      size="large"
      type="password"
    />
    <el-button size="large" @click="resetPassword">Reset your password</el-button>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  margin: auto;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
}
</style>
