<script lang="ts" setup>
    import { ref } from 'vue';
    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const selected = ref<string>(props.modelValue?.toString() || '');
    const emit = defineEmits(["onValueChange"]);
    const onValueChange = () => {
        emit('onValueChange', selected.value, selected.value == "No" ? "invalid" : 'ok')
    }
    onValueChange();
</script>

<template>
    <div
        style="justify-content: center; justify-items: center; display: flex"
      >
        <el-select
          v-model="selected"
          placeholder="Select"
          size="large"
          style="width: 90%"
          @change="onValueChange"
        >
          <el-option
            v-for="(item, index) in props.options"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
    </div>
</template>

<style scoped>

</style>