<script lang="ts" setup>
    import type { SignaturePadRef } from '@/interfaces/SignaturePadRef';
    import { ref, onBeforeMount, onDeactivated, reactive } from 'vue';
    import Vue3Signature from 'vue3-signature';

    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const emit = defineEmits(["onValueChange"]);
    const signaturePad = ref<SignaturePadRef>()
    const name = ref<string>('');
    const error = ref<boolean>(false);
    const signaturePadState = reactive({
        count: 0,
        option: {
            penColor: 'rgb(0, 0, 0)',
            backgroundColor: 'rgb(255,255,255)'
        },
        disabled: false
    });

    const saveSignature = () => {
        if (signaturePad.value) {
            error.value = false
            if (name.value == "") {
                error.value = true
                return;
            }
            emit('onValueChange', name.value + "|" + signaturePad.value!.save(), signaturePad.value!.isEmpty() ? "invalid" : "ok");
        }
    }
    const clearSignature =() => signaturePad.value!.clear();

    let int: number = 0;    
    onBeforeMount(() => { 
        emit('onValueChange', "|", 'invalid');
    });
    onDeactivated(() => { 
        window.clearInterval(int);
    });
</script>

<template>
    <div>
        <div class="signature-pad-container">
            <Vue3Signature
                ref="signaturePad"
                :disabled="signaturePadState.disabled"
                :sigOption="signaturePadState.option"
            ></Vue3Signature>
        </div>
        <div class="eraser">
            <i @click="clearSignature" class="fas fa-eraser"></i>
        </div>

        <div class="name-container" style="margin-top: 30px;">
            <label>Your Name:</label>
            <el-input @keyup="saveSignature" v-model="name" placeholder="Enter your name"></el-input>
            <div v-if="error"  style="color: red">Please enter a value</div>
        </div>
    </div>
</template>

<style scoped>
.signature-pad-container {
  border: #7d7d7d 1px solid;
  width: 550px;
  height: 200px;
  margin: 20px auto auto;
  position:relative;
  max-width:100%;
}
.name-container {
    height:100%;
    width: 550px;
    max-width:100%;
    margin: 0 auto;
}
.eraser{
  font-size: 2em;
  position: absolute;
  bottom: -50px;
  right: 0px;
}
</style>