<script lang="ts" setup>
import { ElNotification } from "element-plus";
import {ref, inject, onBeforeMount} from "vue";

const worker:Worker = inject("worker") as Worker;
const refAudio = ref<HTMLAudioElement | null>(null);

worker.onmessage = (event) => {
  if (event.data.type === "notification") {
    try {
      refAudio.value?.play().catch((e) => {
        console.error("Audio permission is not granted.");
      });
    } catch (e) {
      console.error("Audio permission is not granted.");
    }
    ElNotification({
      title: "CheckMate notification",
      message: event.data.message,
      type: "info",
      duration: 0,
      showClose: true
    });
  }
};

onBeforeMount(() => {
    if (window.Notification && window.Notification.permission !== "granted") {
        window.Notification.requestPermission(function (p) {
            if (p === "granted") {
                console.log("Notification permission granted.");
            } else {
                console.log("Notification permission denied.");
            }
        });
    }
});

</script>
<template>
  <div>
    <audio ref="refAudio" autoplay="false">
      <source src="/audio/notification.mp3" type="audio/mp3">
    </audio>
  </div>
</template>