<script lang="ts" setup>
import CheckWizardTabComponent from '@/components/CheckWizardTabComponent.vue'
import { useChecksStore } from '@/stores/checksStore'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import type { CheckTask } from '@/interfaces/CheckTask'
import ReportIssueModalComponent from '@/components/ReportIssueModalComponent.vue'
import { ChecksService } from '@/services/ChecksService'
import type { CompletedCheck } from '@/interfaces/CompletedCheck'
import type { CheckAnswer } from '@/interfaces/CheckAnswer'
import type { SaveCompletedCheckResponse } from '@/interfaces/SaveCompletedCheckResponse'
import { ElNotification } from 'element-plus'
import { offlineDatabase } from '@/offlineDatabase'
import { OfflineDatabaseService } from '@/services/OfflineDatabaseService'
import { CheckOfflineService } from '@/services/CheckOfflineService'
import { useRouter } from 'vue-router'
import { CheckStep } from '@/enums/CheckStep'




const props = defineProps<{
  startstepid: string | undefined
}>()
const router = useRouter()
const checksStore = useChecksStore()
const { currentCheckMetadata } = storeToRefs(checksStore)
const offlineDatabaseService = OfflineDatabaseService()
const checkOfflineService = CheckOfflineService()
const checkTasks = ref(
  currentCheckMetadata.value!.check.checkTasks.sort((a, b) => (a.order > b.order ? 1 : -1))
)
const checkName = ref(currentCheckMetadata.value!.check.title)

const isReportIssueModalComponentOpen = ref(false)
const reportIssueModalRef = ref(null as any)
const activeTab = ref(props.startstepid == undefined ? currentCheckMetadata.value!.check.checkTasks[0].id : props.startstepid)

onMounted(async () => {
  //await retrieveLastCheckTaskFromOfflineDatabase()
})

const retrieveLastCheckTaskFromOfflineDatabase = async (): Promise<void> => {
  const lastCompletedTaskId: string | undefined =
    await offlineDatabaseService.getLastCompletedTaskIdByCheckOfflineGuid(
      currentCheckMetadata.value?.guid
    )

  if (!lastCompletedTaskId) {
    return
  }

  const lastCompletedTaskIndex = currentCheckMetadata.value?.check.checkTasks.findIndex(
    (ct) => ct.id == lastCompletedTaskId.toString()
  )

  if (!lastCompletedTaskIndex) {
    return
  }

  activeTab.value = currentCheckMetadata.value!.check.checkTasks[lastCompletedTaskIndex!].id
}

const openReportIssueModal = (task: CheckTask) => {
  if (reportIssueModalRef.value) {
    const checkId: string = task.checkId
    const taskId: string = task.id
    reportIssueModalRef.value.setCheckId(checkId)
    reportIssueModalRef.value.setTaskId(taskId)
  }

  isReportIssueModalComponentOpen.value = true
}

const onNextTabClick = async (currentIndex: number, checkTask: CheckTask) => {
  activeTab.value = checkTasks.value.at(currentIndex + 1)!.id

  await checkOfflineService.updateCheckTaskProgress(
    currentCheckMetadata.value!.guid!,
    checkTask,
    checkTask.value,
    CheckStep.TasksWizard
  )
}

const onPreviousTabClick = async (currentIndex: number, checkTask: CheckTask) => {
  activeTab.value = checkTasks.value.at(currentIndex - 1)!.id

 
}

const onValueChange = async (checkTask: CheckTask, newValue: any) => {
  checkTask.value = newValue

  await checkOfflineService.updateCheckTaskProgress(
    currentCheckMetadata.value!.guid,
    checkTask,
    newValue,
    CheckStep.TasksWizard
  )
}

const onFinish = async () => {
  const checksService = new ChecksService()
  const refreshedCheck = await offlineDatabaseService.getCheckByGuid(currentCheckMetadata.value!.guid);
  const completedCheck: CompletedCheck = {
    checkId: currentCheckMetadata.value!.check.id,
    answers: currentCheckMetadata.value!.check.checkTasks.map(
      (task: CheckTask) =>
        ({
          taskId: task.id,
          value: task.value ? (task.value as string) : ''
        }) as CheckAnswer
    ) as CheckAnswer[],
    issues: refreshedCheck!.check.issues,
  }
  
  let result: SaveCompletedCheckResponse = await checksService.saveCompletedCheck(completedCheck)

  if (result.success) {
    ElNotification({
      title: 'Success',
      message: 'Check has been saved',
      type: 'success',
      customClass: 'notification'
    })
    
    if (currentCheckMetadata.value!.check.canBeFinishedMultipleTimes) {
      offlineDatabase.checkOfflineMetadata.where("guid").equals(currentCheckMetadata.value!.guid).delete();
    } else {
      console.log("id is", currentCheckMetadata.value!.id, currentCheckMetadata.value)
      offlineDatabase.checkOfflineMetadata.where("guid").equals(currentCheckMetadata.value!.guid).modify({ 
        "check.isCompleted": true, 
        "currentCheckStep": CheckStep.Initial 
      });
    }

    await router.push('/account/dashboard')
    return
  }
  ElNotification({
    title: 'Error',
    message: 'Check could not been saved',
    type: 'error',
    customClass: 'notification'
  })
}

const onCancelCheckClicked = () => {
  checkOfflineService.cancelCheck(currentCheckMetadata!.value!)

  router.push('/account/dashboard')
}
</script>

<template>
  
    <n-tabs
      :key="activeTab"
      v-if="checkTasks.length > 0"
      v-model:value="activeTab"
      animated
      pane-wrapper-style="background-color: white; height: 100%; border-radius: 8px "
      style="height: 100%"
      tab-style="display: none"
    >
      <n-tab-pane
        v-for="(check, index) in checkTasks.sort((a: any, b: any) => (a.order > b.order ? 1 : -1))"
        :key="index"
        :name="check.id"
        :tab="check.id"
        style="height: 100%"
      >
        <CheckWizardTabComponent
          :CheckName="checkName"
          :CheckTask="check"
          :CheckTaskOfflineValue="check.value"
          :PageNumber="index + 1"
          :ShowNextTabButton="checkTasks.length > index + 1"
          :ShowPreviousTabButton="index >= 1"
          :TotalPageNumber="checkTasks.length"
          @onCancelCheckClicked="() => onCancelCheckClicked()"
          @onFinish="onFinish"
          @onNextTabClick="onNextTabClick(index, check)"
          @onPreviousTabClick="onPreviousTabClick(index, check)"
          @onReportIssueClick="() => openReportIssueModal(check)"
          @onValueChange="(value) => onValueChange(check, value)"
        />
      </n-tab-pane>
    </n-tabs>
  

  <ReportIssueModalComponent
    ref="reportIssueModalRef"
    :is-open="isReportIssueModalComponentOpen"
    @close="isReportIssueModalComponentOpen = false"
  />
</template>

<style scoped>
.n-config-provider{height: 100%}
</style>
