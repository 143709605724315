<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import NotificationComponent from '@/components/NotificationComponent.vue'
import { type RouteLocationRaw, useRouter } from 'vue-router'
import { AuthenticationService } from '@/services/AuthenticationService'
import type { UserBasicInformation } from '@/interfaces/UserBasicInformation'
import { useNotificationStore } from '@/stores/notificationStore'
import { clearDatabase } from '@/offlineDatabase'
import { DatabaseSynchronizationService } from '@/services/DatabaseSynchronizationService'
import { useChecksStore } from '@/stores/checksStore'
import { offlineDatabase } from '@/offlineDatabase'
import LocationSelect from '@/components/LocationSelect.vue'

const router = useRouter()
const drawer = ref(false)
const notificationsDrawerPage = ref(false)
const user: UserBasicInformation = JSON.parse(localStorage.getItem('user')!)
const notificationStore = useNotificationStore()
const dialogVisible = ref(false)
const databaseSynchronizationService = DatabaseSynchronizationService()
const checkStore = useChecksStore()
const appVersion = ref(import.meta.env.VITE_APP_VERSION);

onMounted(async () => {
  await notificationStore.getNotifications()
})

const navigateTo = (webpage: RouteLocationRaw) => {
  router.push(webpage)
  drawer.value = false
  notificationsDrawerPage.value = false
}

const openNotifications = () => {
  notificationsDrawerPage.value = true
}

const logout = () => {
  const authenticationService = new AuthenticationService()
  authenticationService.logout()
  window.location.assign("/");
}

const clearLocalDatabase = async () => {
  clearDatabase();
  checkStore.offlineChecksMetadata = [];
  await databaseSynchronizationService.synchronize()
  checkStore.setChecks(await offlineDatabase.checkOfflineMetadata.toArray())
  window.location.reload()
}

const onNotificationDrawerOpened = async () => {
  await notificationStore.setNotificationsAsSeen()
}
</script>

<template>
  <el-container>
    <el-header class="header">
      <el-row class="header-row" justify="space-between">
        <el-col :span="6">
          <el-row
            justify="space-around"
            style="margin-left: 15px; justify-content: start; gap: 30px"
          >
            <i
              class="fa-solid fa-house"
              style="font-size: 25px"
              @click="$router.push('/account/dashboard')"
            ></i>
          </el-row>
        </el-col>
        <el-col :span="6" class="logo-column">
          <img
            height="auto"
            src="../assets/checkmate-logo.png"
            style="cursor: pointer"
            width="120px"
            @click="$router.push('/account/dashboard')"
          />
        </el-col>
        <el-col :span="10">
          <el-row
            justify="space-between"
            style="margin-right: 20px; justify-content: end; gap: 30px"
          >
            <el-badge
              v-if="notificationStore.notificationsState!.filter((n) => !n.seenOn).length > 0"
              :value="notificationStore.notificationsState!.filter((n) => !n.seenOn).length"
              class="icon"
              style="cursor: pointer"
              @click="openNotifications()"
            >
              <i class="fa-regular fa-bell"></i>
            </el-badge>
            <i class="fa-regular fa-bell" style="margin-top: 5px; font-size: 1.7em;" @click="openNotifications()" v-else></i>
            <img
              height="24"
              src="../assets/bars-solid.svg"
              style="margin-top: 5px"
              width="auto"
              @click="drawer = true"
            />
          </el-row>
        </el-col>
      </el-row>
    </el-header>

    <el-main class="main">
      <RouterView />
    </el-main>
  </el-container>

  <el-drawer v-model="drawer" :direction="'rtl'" style="color: black; min-width: 300px">
    <template #default>
      <div
        style="display: flex; flex-direction: column; row-gap: 20px; justify-content: space-between"
      >
        <div style="display: flex; align-items: center">
          <i class="fa-regular fa-clock" style="font-size: 22px"></i>
          <el-text
            style="font-size: 22px; position: fixed; margin-left: 35px; cursor: pointer"
            @click="navigateTo('/account/dashboard')"
            >Checks
          </el-text>
        </div>
        <div style="display: flex; align-items: center">
          <i class="fa-regular fa-bell" style="font-size: 22px"></i>
          <el-text
            style="font-size: 22px; position: fixed; margin-left: 35px; cursor: pointer"
            @click="notificationsDrawerPage = true"
            >Notifications
          </el-text>
        </div>

        
      </div>
    </template>

    <template #footer>
      <div
        style="
          position: absolute;
          bottom: 40px;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          width: 100%;
        "
      >
        <div style="background: black; height: 1px; margin: 20px" />
        <div class="location-select">
          <LocationSelect />
        </div>

        <el-text class="drawer-footer-menu-item" @click="logout">Logout</el-text>
        <div class="small" @click="dialogVisible = true">App version {{appVersion}}</div>
      </div>
    </template>
  </el-drawer>
  <el-drawer
      v-model="notificationsDrawerPage"
      :append-to-body="true"
      style="color: black; min-width: 300px"
      title="Notifications"
      @opened="onNotificationDrawerOpened"
    >
      <div v-for="notification in notificationStore.notificationsState">
        <NotificationComponent
          :date="`${new Date(notification.createdOn).toDateString()} ${new Date(notification.createdOn).toLocaleTimeString()}`"
          :title="notification.message"
          :type="notification.category"
        />
      </div>
      <div v-if="notificationStore.notificationsState!.length === 0">
        <el-text style="font-size: 22px; margin-left: 100px">No notifications</el-text>
      </div>  
    </el-drawer>

    <el-dialog
      v-model="dialogVisible"
      title=""
      width="320"
    >
    <div style="text-align: center;">
      <div>
        <i class="fas fa-circle-exclamation" style="color: #F56C6C; font-size: 4em;"></i>
      </div>
      <div style="padding: 20px;">
        Are you sure you want to clear your app? This will remove any checks you currently have in progress.
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer" style="text-align:center;">
        <el-button type="primary" @click="dialogVisible = false">No take me back to safety</el-button>
        <el-button  @click="clearLocalDatabase()">
          Yes I want to reset my app
        </el-button>
      </div>
    </template>
  </el-dialog>


</template>

<style scoped>
.header {
  background: #151740;
  min-height: 90px;
  margin: 0;
  padding: 0;
}

.header-row {
  height: 100%;
  align-items: center;
}

.main {
  
  color: black;
  width: 100%;
  background: #f3f3f5 !important;
}


.logo-column {
  position: absolute;
  right: 45%;
  margin-top: 10px;
}

.icon {
  font-size: 24px;
}

.account-row {
  background: white;
  color: black;
  height: 40px;
}

.drawer-footer-menu-item {
  font-size: 22px;
  width: 100%;
  display: flex;
  margin-left: 100px;
  cursor: pointer;
  user-select: none;
}

.location-select {
  width: 100%;
  padding: 0 50px 20px 50px;
}

.small{
  font-size: 0.8em;
  margin-top: 30px;
  text-align:center;
}

</style>
