<script lang="ts" setup>
import type { CheckOfflineMetadata } from '@/offlineDatabase'
import { InputType } from '@/enums/InputType';
const emit = defineEmits(["taskClicked"])
const props = defineProps<{
  checkOfflineMetadata: CheckOfflineMetadata
}>()

const isTaskFinished = (taskId: string): boolean => {
  const task = props.checkOfflineMetadata.check.checkTasks.find((t) => t.id == taskId)
  if (!task) {
    return false
  }
  if (task.value == undefined) return false;
  return task.isCompleted
}
const isTaskNext = (taskId: string): boolean => {
  const tasks = props.checkOfflineMetadata.check.checkTasks.filter((t) => t.isCompleted)
  if (!tasks) return false
  const taskOrderItem = Math.max(...tasks.map(x => x.order))
  const currentTask = props.checkOfflineMetadata.check.checkTasks.find((t) => t.id == taskId);
  if (!currentTask) return false;
  if (currentTask.order == taskOrderItem+1) return true;
  return false;  
}
const onTaskClicked = (id:string) => {
  if (isTaskFinished(id) || isTaskNext(id)) {
    emit("taskClicked", id);
  }
}
</script>

<template>
  <div style="width: auto; margin-bottom: 10px">
    <div style="margin-bottom: 30px">
      <el-text size="large" tag="b">Tasks</el-text>
    </div>
    <div v-for="task in props.checkOfflineMetadata.check.checkTasks.filter(x => x.inputType != InputType.Timestamp)">
      <div style="display: flex; flex-direction: column; gap: 0">
        <div
          style="
            border-radius: 5px;
            margin-top: 8px;
            display: flex;
            justify-content: start;
            align-items: center;
            padding-left: 5px;
          "
          :class="[isTaskFinished(task.id) ? 'task-finished' : 'task-unfinished', isTaskNext(task.id) ? 'task-next' : '']"
           @click="onTaskClicked(task.id)"
        >
          <div
            style="
              display: flex;
              justify-content: start;
              vertical-align: center;
              align-items: center;
            "
          >
            <i v-if="isTaskFinished(task.id)" class="fa-solid fa-check check-icon"></i>
            <i v-if="isTaskNext(task.id)" class="fa-solid fa-arrow-right check-icon"></i>
            <el-text line-clamp="1">&nbsp;&nbsp;{{ task.label }}</el-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.task-unfinished,
.task-finished,
.task-next {
  font-size: 1.3em;
  padding: 10px;
}

.task-finished{
  background-color: #79b342;
}
.task-next{
  background-color: #f3a127!important;
}
.task-unfinished{
  background: #ececec
}
.task-finished span,
.task-next span{
  color: #efefef;
}
.task-unfinished span,
.task-finished span {
  text-align: left;
}
.check-icon{
  margin-left: 5px;
}
</style>