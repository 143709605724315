import { ApiClient } from '@/apiClient'
import type { GetSettingsResponse } from '@/interfaces/GetSettingsResponse'
import type { Setting } from '@/interfaces/Setting'

export class SettingsService {
  apiClient: ApiClient = new ApiClient()

  async getSettings(): Promise<GetSettingsResponse> {
    const getSettingResponse: GetSettingsResponse = {
      success: false
    }

    await this.apiClient
      .getSettings()
      .then((response: any) => {
        if (response.status === 200) {
          getSettingResponse.success = true
          getSettingResponse.settings = response.data

          return response
        }

        getSettingResponse.error = response.data.errors
        return response
      })
      .catch((_: any) => {
        getSettingResponse.error = 'Server error'
      })

    return getSettingResponse
  }

  async update(settings:Setting): Promise<GetSettingsResponse> {
    const getSettingResponse: GetSettingsResponse = {
      success: false
    }

    await this.apiClient
      .updateSettings(settings)
      .then((response: any) => {
        if (response.status === 200) {
          getSettingResponse.success = true
          getSettingResponse.settings = response.data

          return response
        }

        getSettingResponse.error = response.data.errors
        return response
      })
      .catch((_: any) => {
        getSettingResponse.error = 'Server error'
      })

    return getSettingResponse
  }
}
