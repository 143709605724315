import { defineStore } from 'pinia'
import type { Location } from '@/interfaces/Location'
import { ref } from 'vue'
import {LocationService} from '@/services/LocationService'


export const useLocationStore = defineStore('locationStore', () => {
  const locations = ref<Location[]>([])
  const locationService = new LocationService();
  const getLocations = async () => {
    let response = await locationService.getLocations();
    locations.value = response;
  }
  const setLocation = async (id: string) => {
    await locationService.setLocation(id);
  }



  return {
    getLocations,
    setLocation,

    locations
  }
})
