import type { ResetPasswordRequest } from '@/interfaces/ResetPasswordRequest'
import { ApiClient } from '@/apiClient'
import type { ResetPasswordResponse } from '@/interfaces/ResetPasswordResponse'

export class UserService {
  apiClient: ApiClient = new ApiClient()

  async resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<ResetPasswordResponse> {
    const resetPasswordResponse: ResetPasswordResponse = {
      success: false
    }

    await this.apiClient
      .resetPassword(resetPasswordRequest)
      .then((response: any) => {
        if (response.success || response.status === 201) {
          resetPasswordResponse.success = true
          return response
        }

        resetPasswordResponse.error = response.data.errors
        return response
      })
      .catch((_: any) => {
        resetPasswordResponse.error = 'Server error'
      })

    return resetPasswordResponse
  }

  async sendVerificationCode(email: string): Promise<void> {
    await this.apiClient.sendVerificationCode(email)
  }
}
