export const enum InputType {
  Text,
  TextArea,
  CheckBox,
  FridgeTemp,
  FreezerTemp,
  HotHoldingTemp,
  CookTemp,
  Signature,
  Select,
  Message,
  Timestamp,
  CoolingTemp,
  HotProbeTemp,
  ColdProbeTemp,
  ColdHoldingTemp,
  RegenerationTemp,
  CheckBoxNoExpected
}

export const isInputTypeTemperature = (inputType: InputType) => {
  return (
    inputType === InputType.FreezerTemp ||
    inputType === InputType.FridgeTemp ||
    inputType === InputType.CookTemp ||
    inputType === InputType.HotHoldingTemp ||
    inputType === InputType.ColdHoldingTemp ||
    inputType === InputType.CoolingTemp ||
    inputType === InputType.HotProbeTemp ||
    inputType === InputType.ColdProbeTemp ||
    inputType === InputType.RegenerationTemp
  )
}
