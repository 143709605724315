import { ApiClient } from '@/apiClient'
import type { Issue } from '@/interfaces/Issue'
import type { GenericApiResponse } from '@/interfaces/GenericApiResponse'

export class IssueService {
  apiClient = new ApiClient()

  async saveIssue(issue: Issue): Promise<GenericApiResponse> {
    const apiResponse: GenericApiResponse = {
      success: false
    }

    await this.apiClient
      .saveIssue(issue)
      .then((response: any) => {
        if (response.success || response.status === 201) {
          apiResponse.success = true
          return response
        }
      })
      .catch(() => {
        apiResponse.error = 'Unexpected error occurred'
      })

    return apiResponse
  }
}
