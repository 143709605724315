<script lang="ts" setup>
import { ref, onBeforeMount } from 'vue'
import CorrectiveActionsModalComponent from '@/components/CorrectiveActionsModal/CorrectiveActionsModal.vue'
import { InputType, isInputTypeTemperature } from '@/enums/InputType'
import { ElNotification } from 'element-plus'
import type { CorrectiveActionsModalRef } from '@/components/CorrectiveActionsModal/CorrectiveActionsModalRef'
import type { CheckTask } from '@/interfaces/CheckTask'
import { useSettingsStore } from '@/stores/settingsStore'
import { useChecksStore } from '@/stores/checksStore'
import type { Issue } from '@/interfaces/Issue'
import MessageComponent from './tasks/MessageComponent.vue'
import SignatureComponent from './tasks/SignatureComponent.vue'
import TemperatureComponent from './tasks/TemperatureComponent.vue'
import TextareaComponent from './tasks/TextareaComponent.vue'
import TextComponent from './tasks/TextComponent.vue'
import CheckboxComponent from './tasks/CheckboxComponent.vue'
import CheckboxNoExpectedComponent from './tasks/CheckboxNoExpectedComponent.vue'
import DropdownComponent from './tasks/DropdownComponent.vue'
import { inject } from 'vue';
import { PropModSymbol } from 'dexie'

const worker: Worker = inject('worker')!;
const emit = defineEmits({
  onValueChange: (value: number | string | boolean | undefined | 'Yes' | 'No') => value,
  onNextTabClick: () => true,
  onPreviousTabClick: () => true,
  onReportIssueClick: () => true,
  onFinish: () => true,
  onCancelCheckClicked: () => true
})

let props = defineProps<{
  CheckName:string,
  CheckTask: CheckTask
  CheckTaskOfflineValue: number | string | boolean | undefined | 'Yes' | 'No'
  ShowNextTabButton: boolean
  ShowPreviousTabButton: boolean
  PageNumber: number
  TotalPageNumber: number
}>()

const settingsStore = useSettingsStore();
const checkStore = useChecksStore();
const value = ref<number | string | boolean | undefined | 'Yes' | 'No'>(undefined);
const max = ref<number | undefined>(undefined);
const min = ref<number | undefined>(undefined);
const options = ref<string[] | string | undefined>(undefined);
const validationResult = ref<'under' | 'over' | 'invalid' | 'ok'>("ok");
const confirmCancelDialogVisible = ref<boolean>(false)
const correctiveActionsModalComponentRef = ref<CorrectiveActionsModalRef>()
const loading = ref(false)

onBeforeMount(() => {
  if (props.CheckTask.inputType == InputType.Timestamp || props.CheckTask.inputType == InputType.Message) {
    emit('onValueChange', new Date().toLocaleString());
    validationResult.value = 'ok';
    if (props.CheckTask.inputType == InputType.Timestamp) onNextButtonClick();
  }

  setAlarm()
  
  //  Default to pre-selected or avg temp
  if (isInputTypeTemperature(props.CheckTask.inputType)) {
    const settings = settingsStore.getTemperatureSettings(props.CheckTask.inputType)
    value.value = 0.0;
    max.value = settings?.max;
    min.value = settings?.min;
  }

  if (props.CheckTaskOfflineValue) {
    value.value = props.CheckTaskOfflineValue
  }
  options.value = props.CheckTask.inputTypeValues;
});

const setAlarm = () => {
  if (props.CheckTask.alarmMinutes > 0) 
    worker.postMessage({ 
      type: 'alarm',
      minutes: props.CheckTask.alarmMinutes,
      checkId: props.CheckTask.checkId,
      message: `${props.CheckName} is due`
    });
}

const checkValidation = () : boolean => {
  if (validationResult.value === 'ok') return true; 
  if (issueAlreadyLogged()) return true;
  return false;
}
const issueAlreadyLogged = () => {
  if (checkStore.currentCheckMetadata != undefined && checkStore.currentCheckMetadata?.check.issues == undefined) 
    checkStore.currentCheckMetadata.check.issues = [];
  return checkStore.currentCheckMetadata?.check.issues.find(x => x.taskId === props.CheckTask.id) != null
}
const openCorrectiveActionsModal = () => {
  if (!correctiveActionsModalComponentRef.value) return;

  if (!issueAlreadyLogged()){
    correctiveActionsModalComponentRef.value!.openModal(
      props.CheckTask.checkId,
      props.CheckTask.id,
      props.CheckTask.label,
      props.CheckTask.correctiveActions,
      validationResult.value
    )
  }
}
const validateStep = () => {
  //  Sepcial case for signature
  if (props.CheckTask.inputType == InputType.Signature) {
    if (validationResult.value == 'invalid') {
      ElNotification({
        title: 'Warning',
        message: 'Signature & name is required',
        type: 'warning',
        customClass: 'notification'
      })
      return
    }
  }
  //  Textareas and dropdowns cannot conitnue without a value
  if (props.CheckTask.inputType == InputType.Text ||
    props.CheckTask.inputType == InputType.TextArea ||
    props.CheckTask.inputType == InputType.Select) {
    if (value.value == undefined || value.value == "") {
      ElNotification({
        title: 'Warning',
        message: 'Please enter a value',
        type: 'warning',
        customClass: 'notification'
      })
      return
    }
  }

  //  Normal circumstances
  if (checkValidation()) {
    if (props.ShowNextTabButton) {
      emit('onNextTabClick');
    } else {
      finish();
    }
  } else {
    openCorrectiveActionsModal();
  }
}
const finish = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
  }, 10000); 
  worker.postMessage({ type: 'removealarm', checkId: props.CheckTask.checkId });
  worker.postMessage({ type: 'removealert', checkId: props.CheckTask.checkId });
  emit('onFinish');
}
const onValueChanged = (val: number | string | boolean | undefined | 'Yes' | 'No', outcome: 'under' | 'over' | 'invalid' | 'ok') => {
  validationResult.value = outcome;
  value.value = val;
  emit('onValueChange', val);
}
const onCorrectiveActionsConfirmed = (issue: Issue) => {
  checkStore.currentCheckMetadata?.check.issues.push(issue)
  if (props.ShowNextTabButton) {
    emit('onNextTabClick');
  } else {
    finish();
  }
}
const onNextButtonClick = () => {
  validateStep()
}
const onFinish = (): void => {
  validateStep();
}
const skip = () => {
  emit('onValueChange', "Skipped");
  validationResult.value = 'ok';
  onNextButtonClick();
}
const onCancelCheckClicked = () => {
  confirmCancelDialogVisible.value = false
  emit('onCancelCheckClicked')
}
</script>

<template>
  <div class="pane-content-container">
    <el-text style="margin-bottom: 30px">{{ PageNumber }} of {{ TotalPageNumber }}</el-text>
    <el-text size="large" style="font-weight: bold; color: #151740; text-align: center"
      >{{ props.CheckTask.label }}
    </el-text>
    <el-text
      v-if="props.CheckTask.description"
      style="margin-top: 20px; display: flex; width: 88%; justify-content: center"
      >{{ props.CheckTask.description }}
    </el-text>

    <div class="pane-content">
      <TextComponent 
        v-if="props.CheckTask.inputType == InputType.Text" 
        v-model="value" 
        :min="min"
        :max="max"
        :options="options"
        @onValueChange="onValueChanged" 
      />
      <TextareaComponent 
        v-if="props.CheckTask.inputType == InputType.TextArea" 
        v-model="value" 
        :min="min"
        :max="max"
        :options="options"
        @onValueChange="onValueChanged" 
      />
      <CheckboxComponent 
        v-if="props.CheckTask.inputType == InputType.CheckBox"
        v-model="value" 
        :min="min"
        :max="max"
        :options="options"
        @onValueChange="onValueChanged"   
      />
      <CheckboxNoExpectedComponent 
        v-if="props.CheckTask.inputType == InputType.CheckBoxNoExpected"
        v-model="value" 
        :min="min"
        :max="max"
        :options="options"
        @onValueChange="onValueChanged"   
      />
      <DropdownComponent 
        v-if="props.CheckTask.inputType == InputType.Select"
        v-model="value" 
        :min="min"
        :max="max"
        :options="options"
        @onValueChange="onValueChanged" 
      />
      <MessageComponent 
        v-if="props.CheckTask.inputType == InputType.Message" 
        v-model="value" 
        :min="min"
        :max="max"
        :options="options"
        @onValueChange="onValueChanged" 
      />
      <TemperatureComponent
        v-if="isInputTypeTemperature(props.CheckTask.inputType)"
        v-model="value" 
        :min="min"
        :max="max"
        :options="options"
        @onValueChange="onValueChanged" 
      />
      <SignatureComponent 
        v-if="props.CheckTask.inputType == InputType.Signature" 
        v-model="value" 
        :min="min"
        :max="max"
        :options="options"
        @onValueChange="onValueChanged" 
      />
    </div>
    <div v-if="props.CheckTask.skippable" class="centered">
      <el-button class="btn-skip" @click="skip">Skip this step</el-button> 
    </div>

    <div style="margin-top: auto;" class="buttonSection">
      <div style="display: flex; padding: 0px">     
        <el-button
          :disabled="!ShowPreviousTabButton"
          class="bottom-buttons previous-button"
          @click="$emit('onPreviousTabClick')"
          >Previous
        </el-button>
        

        <el-button class="bottom-buttons report-issue-button" @click="$emit('onReportIssueClick')"
          ><i class="fa fa-triangle-exclamation"></i>
        </el-button>
        
        <el-button class="bottom-buttons cancel-button" @click="confirmCancelDialogVisible = true">
          <i class="fa fa-x"></i>
        </el-button>


        <el-button
          v-if="ShowNextTabButton"
          class="bottom-buttons next-button"
          @click="onNextButtonClick()"
          >Next
        </el-button>

        <el-button
          :loading="loading"
          v-if="PageNumber == TotalPageNumber"
          class="bottom-buttons next-button"
          @click="onFinish"
          >Finish
        </el-button>

      </div>
    </div>
  </div>

  <el-dialog v-model="confirmCancelDialogVisible" title="Cancel check" width="400">
    <span>Are you sure you want to cancel?</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="confirmCancelDialogVisible = false">No</el-button>
        <el-button type="primary" @click="onCancelCheckClicked">Yes</el-button>
      </div>
    </template>
  </el-dialog>

  <CorrectiveActionsModalComponent
    ref="correctiveActionsModalComponentRef"
    @sent="onCorrectiveActionsConfirmed"
  />
</template>

<style scoped>
.centered{
  text-align:center;
  margin: 30px;
}

.pane-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.pane-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.bottom-buttons {
  border-radius: 0;
  height: 75px;
  font-size: 20px;
  margin:0!important;
}

.report-issue-button {
  --el-button-bg-color: #dfae45;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #d38f36;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.cancel-button {
  --el-button-bg-color: #e1341e;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #e1341e;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.next-button {
  --el-button-bg-color: #79b342;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #79b342;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.previous-button {
  --el-button-bg-color: #009de0;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #009de0;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.buttonSection{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
}

.btn-skip{
  font-size: 1.5em;
  margin-top: 10px;
}
</style>
