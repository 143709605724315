<script lang="ts" setup>
import { onBeforeMount, ref, onActivated } from 'vue'
import ReportIssueModalComponent from '@/components/ReportIssueModalComponent.vue'
import { useChecksStore } from '@/stores/checksStore'
import { SettingsService } from '@/services/SettingsService'
import type { GetSettingsResponse } from '@/interfaces/GetSettingsResponse'
import { offlineDatabase, removeOldRecordsFromOfflineDatabase } from '@/offlineDatabase'
import { useSettingsStore } from '@/stores/settingsStore'
import ChecksInProgress from '@/components/dashboard/ChecksInProgress.vue'
import ChecksList from '@/components/dashboard/ChecksList.vue'
import { DatabaseSynchronizationService } from '@/services/DatabaseSynchronizationService'

const isReportIssueModalComponentOpen = ref(false)

const checksStore = useChecksStore()
const settingsStore = useSettingsStore()
const settingsService = new SettingsService()
const databaseSynchronizationService = DatabaseSynchronizationService()

onBeforeMount(async () => {
  await removeOldRecordsFromOfflineDatabase()
  await databaseSynchronizationService.synchronize()
  await getSettings()

  checksStore.setChecks(await offlineDatabase.checkOfflineMetadata.toArray())
})
onActivated(async () => {
  await removeOldRecordsFromOfflineDatabase()
  await databaseSynchronizationService.synchronize()
  await getSettings()

  checksStore.setChecks(await offlineDatabase.checkOfflineMetadata.toArray())
})

const openReportIssueModalComponent = () => {
  isReportIssueModalComponentOpen.value = true
}

const getSettings = async (): Promise<void> => {
  const getSettingsResponse: GetSettingsResponse = await settingsService.getSettings()

  if (getSettingsResponse.success) {
    settingsStore.setSettings(getSettingsResponse.settings!)
  }
}
</script>

<template>
  <el-row>
    <el-col :span="24">
      <el-button class="report-issue-button" @click="openReportIssueModalComponent"
        >Report issue
      </el-button>
    </el-col>
  </el-row>

  <el-row style="margin-top: 15px; font-size: 20px">
    <el-col>
      <ChecksInProgress />
    </el-col>
  </el-row>

  <el-row style="margin-top: 15px; font-size: 20px">
    <el-col>
      <ChecksList type="Today" />
    </el-col>
  </el-row>

  <el-row style="margin-top: 15px; font-size: 20px">
    <el-col>
      <ChecksList type="This Week" />
    </el-col>
  </el-row>

  <el-row style="margin-top: 15px; font-size: 20px">
    <el-col>
      <ChecksList type="Extra" />
    </el-col>
  </el-row>

  <ReportIssueModalComponent
    :is-open="isReportIssueModalComponentOpen"
    
    @close="isReportIssueModalComponentOpen = false"
  />
</template>

<style scoped>
.report-issue-button {
  background: #79b342;
  color: white;
  width: 100%;
  height: 45px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: bold;
}

.el-space:deep(.el-space__item) {
  flex-basis: 500px;
}
</style>
