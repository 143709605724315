<script lang="ts" setup>
import TaskList from '@/components/TaskListView.vue'
import { useChecksStore } from '@/stores/checksStore'
import { storeToRefs } from 'pinia'
import { CheckOfflineService } from '@/services/CheckOfflineService'
import { CheckStep } from '@/enums/CheckStep'

const emit = defineEmits(["onStepFinished"])

const checksStore = useChecksStore()
const { currentCheckMetadata } = storeToRefs(checksStore)
const checkOfflineService = CheckOfflineService()

const updateCheckStateToWizard = async () => {
  await checkOfflineService.updateCheckCurrentStep(
    currentCheckMetadata!.value!,
    CheckStep.TasksWizard
  )
  emit('onStepFinished')
}

const taskClicked = async (id: string) => {
  await checkOfflineService.updateCheckCurrentStep(
    currentCheckMetadata!.value!,
    CheckStep.TasksWizard
  )
  emit('onStepFinished', id)
  console.log('Task clicked')
}
</script>

<template>
  <div class="pane-content-container contents">
    <TaskList :checkOfflineMetadata="currentCheckMetadata!" @taskClicked="taskClicked" />
    <div style="margin-top: auto" class="buttonSection">
        <el-button class="next-button no-hover" @click="updateCheckStateToWizard">Next</el-button>
    </div>
  </div>
</template>

<style scoped>
.pane-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.pane-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.next-button {
  width: 100%;
  background: #79b342!important ;
  color: white!important;
  height: 45px;
  font-size: 20px;
  height: 75px;
  font-size: 20px;
  margin:0!important;
}

.buttonSection{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
}
</style>
