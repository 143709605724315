<script lang="ts" setup>
import { ref } from 'vue'
import type { Issue } from '@/interfaces/Issue'
import { ElNotification } from 'element-plus'
import type { CurrentStepValidationResult } from '@/interfaces/CurrentStepValidationResult'
import { CurrentStepValidationFailReason } from '@/enums/CurrentStepValidationFailReason'
import type { CorrectiveAction } from '@/interfaces/CorrectiveAction'
import CorrectiveActionComponent from '@/components/CorrectiveActionComponent.vue'
import {CheckOfflineService} from '@/services/CheckOfflineService'
import { useChecksStore } from '@/stores/checksStore'

const emit = defineEmits(['closeDialog', 'sent'])

const open = ref(false)
const innerDialogOpen = ref(false)
const correctiveActionsDescription = ref()
const correctiveActions = ref<CorrectiveAction[]>([])
const validationResult = ref<'under' | 'over' | 'invalid' | 'ok'>('invalid')
const label = ref<string>()
const checkId = ref<string>()
const taskId = ref<string>()
const checkStore = useChecksStore();
const checkOfflineService = CheckOfflineService();


const openModal = (
  newCheckId: string,
  newTaskId: string,
  newLabel: string,
  newCorrectiveActions: CorrectiveAction[],
  newValidationResult: 'under' | 'over' | 'invalid' | 'ok'
) => {
  checkId.value = newCheckId
  taskId.value = newTaskId
  label.value = newLabel
  correctiveActions.value = newCorrectiveActions
  validationResult.value = newValidationResult
  open.value = true
}

defineExpose({ openModal })

const closeBothDialogs = async () => {
  const task = checkStore.currentCheckMetadata?.check.checkTasks.find(x => x.id === taskId.value);
  const issue: Issue = {
    severity: task != null && task.isCCP ? 'low' : 'high',
    description: correctiveActionsDescription.value,
    checkId: checkId.value!,
    taskId: taskId.value!!
  }

  await checkOfflineService.addIssue(issue, checkStore.currentCheckMetadata?.guid!);

  ElNotification({
    title: 'Success',
    message: 'Corrective actions have been saved',
    type: 'success',
    customClass: 'notification'
  })

  innerDialogOpen.value = false
  open.value = false
  emit('sent', issue)

  checkId.value = ''
  taskId.value = ''
  correctiveActions.value = []
  validationResult.value = 'invalid'
  open.value = false
}
</script>

<template>
  <el-dialog v-model="open" align-center style="width: 90%" @closed="$emit('closeDialog')">
    <div style="display: flex; flex-direction: column; width: 100%">
      <h1>Corrective actions</h1>

      <el-text style="width: 100%; margin-top: 10px; color: #151740"> For: {{ label }}</el-text>

      <h2 style="margin-top: 10px">Actions to take</h2>

      <el-space direction="vertical" fill style="width: 100%; margin-top: 20px">
        <div v-for="action in correctiveActions">
          <CorrectiveActionComponent
            v-if="
              validationResult == 'under'
            "
            :action-to-take="action.lessThanMinimum"
          />

          <CorrectiveActionComponent
            v-if="
              validationResult == 'over'
            "
            :action-to-take="action.moreThanMaximum"
          />

          <CorrectiveActionComponent
            v-if="
              validationResult == 'invalid'
            "
            :action-to-take="action.general"
          />
        </div>
      </el-space>

      <el-dialog v-model="innerDialogOpen" append-to-body width="90%">
        <div style="display: flex; flex-direction: column">
          <h1>Corrective actions</h1>
          <h3>Please detail what actions you took:</h3>

          <el-input
            v-model="correctiveActionsDescription"
            resize="none"
            rows="20"
            style="width: 100%; margin-top: 20px"
            type="textarea"
          />

          <el-button
            class="no-hover"
            style="width: 100%; margin-top: 30px; border-radius: 8px; border: 2px solid #79b342"
            @click="closeBothDialogs"
            >Confirm I have taken corrective actions
          </el-button>
        </div>
      </el-dialog>
    </div>

    <el-button
      class="no-hover"
      style="width: 100%; margin-top: 100px; border-radius: 8px; border: 2px solid #79b342"
      @click="innerDialogOpen = true"
      >Confirm I have taken corrective actions
    </el-button>
  </el-dialog>
</template>

<style scoped></style>
