<script lang="ts" setup>



const props = defineProps<{
    modelValue: number | string | boolean | undefined | 'Yes' | 'No',
    min: number | undefined,
    max: number | undefined,
    options: string[] | string | undefined
}>();
const emit = defineEmits(["onValueChange"]);

</script>

<template>
    <div class="message">
        {{props.options}}
    </div>
</template>

<style scoped>

</style>