<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { useChecksStore } from '@/stores/checksStore'
import type { CheckOfflineMetadata } from '@/offlineDatabase'

const router = useRouter()
const checksStore = useChecksStore()

const props = defineProps<{
  checkOfflineMetadata: CheckOfflineMetadata
}>()

defineEmits(['buttonClick', 'onIssueReportSent'])

const onContinueCheck = (check: CheckOfflineMetadata) => {
  checksStore.setCurrentCheck(check)
  router.push('/account/check')
}
</script>

<template>
  <div class="card" @click="onContinueCheck($props.checkOfflineMetadata)">
    <div
      :class="['card-sidebar']"
      :style="`background-color: ${checkOfflineMetadata.check.icon.colour} !important`"
    >
      <div class="card-icon-container">
        <i :class="[checkOfflineMetadata.check.icon.icon]"></i>
      </div>
    </div>
    <div class="card-content">
      <el-text v-if="checkOfflineMetadata.check.title" class="card-title" size="large"
        >{{ checkOfflineMetadata.check.title }}
      </el-text>
      <el-text
        v-if="checkOfflineMetadata.check.description"
        class="card-description"
        line-clamp="3"
      >
        {{ checkOfflineMetadata.check.description }}
      </el-text>

      <el-button
        class="action-button no-hover"
        @click="onContinueCheck($props.checkOfflineMetadata)"
      >
        Continue check
      </el-button>
    </div>
  </div>
</template>

<style scoped>
.check-icon {
  color: #79b342;
  margin-left: 2px;
  margin-right: 5px;
}

.card {
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 10px;
  overflow: clip;
  min-width: 350px;
  width: 100%;
  margin-bottom: 10px;
}

.card-sidebar {
  background: #151740;
  min-width: 50px;
  min-height: 200px;
  display: flex;
  justify-content: center;
}

.card-sidebar.blue {
  background: #151740;
}

.card-sidebar.green {
  background: #79b342;
}

.card-sidebar.red {
  background: #ff4d4f;
}

.card-sidebar.orange {
  background: #ff9c00;
}

.card-sidebar.yellow {
  background: #f7f11d;
}

.card-sidebar.purple {
  background: #9c27b0;
}

.card-sidebar.lightblue {
  background: #00bcd4;
}

.card-sidebar .card-description {
  background: #79b342;
}

.card-icon-container {
  background: white;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon-container i {
  color: #0f0f0f;
}

.card-title {
  margin-right: auto;
  font-weight: bold;
  color: #151740;
}

.card-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}

.card-sub-title {
  margin-right: auto;
  font-weight: bold;
}

.card-description {
  margin-top: 10px;
  justify-content: left;
  width: 100%;
}

.card-alert-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.card-warning {
  text-align: center;
  margin-left: 5px;
  background: white;
  color: grey;
  font-weight: bold;
}

.card-success-container {
  margin-top: auto;
  display: flex;
  flex-direction: row;
}

.card-success {
  margin-left: 5px;
  background: white;
  color: grey;
  font-weight: bold;
}

.action-button {
  margin: auto 0 0;
  border-color: #79b342;
  border-radius: 8px;
  height: 45px;
  font-weight: bold;
  font-size: 16px;
}
</style>
