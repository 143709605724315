import axios from 'axios'
import type { LoginCredentials } from '@/interfaces/LoginCredentials'
import type { ResetPasswordRequest } from '@/interfaces/ResetPasswordRequest'
import type { CompletedCheck } from '@/interfaces/CompletedCheck'
import type { Issue } from '@/interfaces/Issue'
import type { Setting } from './interfaces/Setting'

export class ApiClient {
  async login(loginCredentials: LoginCredentials): Promise<void> {
    return axios.post('token?d=1', {
      email: loginCredentials.email,
      password: loginCredentials.password
    })
  }

  async resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<void> {
    return axios.post('user/reset-password', {
      email: resetPasswordRequest.email,
      token: resetPasswordRequest.token,
      password: resetPasswordRequest.password
    })
  }

  async sendVerificationCode(email: string): Promise<void> {
    return axios.post('user/forgot-password', {
      email: email
    })
  }

  async getTodaysChecks(): Promise<void> {
    return axios.get('check/list-todays')
  }

  async saveCheck(completedCheck: CompletedCheck): Promise<void> {
    if (completedCheck.issues == undefined) { completedCheck.issues = []; }
    return axios.post('completedchecks/save', {
      checkid: completedCheck.checkId,
      answers: completedCheck.answers,
      notes: completedCheck.notes,
      issues: completedCheck.issues.map(issue => ({severity: issue.severity == "high" ? 1 : 0, description: issue.description, taskId: issue.taskId, checkId: issue.checkId}))
    })
  }

  async saveIssue(issue: Issue): Promise<void> {
    return axios.post('issue/create', {
      id: issue.taskId,
      checkId: issue.checkId,
      taskId: issue.taskId,
      severity: issue.severity,
      description: issue.description
    })
  }

  async getNotifications(): Promise<void> {
    return axios.get('notifications/list')
  }

  async setNotificationsAsSeen(): Promise<void> {
    return axios.post('notifications/setAsSeen')
  }

  async getSettings(): Promise<void> {
    return axios.get('settings')
  }

  async updateSettings(settings: Setting): Promise<void> {
    return axios.post('settings', settings)
  }

  async getLocations(): Promise<void> {
    return axios.get('location')
  }
  async setLocation(id: string): Promise<void> {
    return axios.post('token/set-location', {
      id: id
    })
  }
}
