<script lang="ts" setup>
import { ref } from 'vue'
import { useChecksStore } from '@/stores/checksStore'
import TasksWizard from '@/components/TasksWizard.vue'
import TaskContents from '@/components/TaskContents.vue'
import { CheckStep } from '@/enums/CheckStep'
import FillLateReport from '@/components/FillLateReport.vue'

const checksStore = useChecksStore()
const currentCheckStep = ref<CheckStep>(checksStore.currentCheckMetadata!.currentCheckStep == CheckStep.FillLateReport ? CheckStep.FillLateReport : CheckStep.TaskContents)
const startStepId = ref<string | undefined>(undefined)
const goToStep = (id: string) => {
  startStepId.value = id;
  setCurrentStep(CheckStep.TasksWizard)
}

const setCurrentStep = (checkStep: CheckStep) => {
  currentCheckStep.value = checkStep
}
</script>

<template>
  <div v-if="checksStore.currentCheckMetadata" class="container">
    <div
      :style="
        'background-color: ' +
        checksStore.currentCheckMetadata.check.icon.colour +
        '; border-radius: 6px; padding-left: 10px; padding-right: 10px'
      "
    >
      <i
        :class="checksStore.currentCheckMetadata.check.icon.icon"
        style="font-size: 20px; margin-right: 10px"
      ></i>
      <el-text class="page-title-text">{{ checksStore.currentCheckMetadata.check.title }}</el-text>
    </div>

    <el-text class="page-description-text"
      >{{ checksStore.currentCheckMetadata.check.description }}
    </el-text>

    <n-tabs
      v-model:value="currentCheckStep"
      animated
      pane-wrapper-style="background-color: white; height: 100%; border-radius: 8px "
      style=" height: 100%"
      tab-style="display: none"
    >
      <n-tab-pane
        :name="CheckStep.FillLateReport"
        :tab="CheckStep.FillLateReport"
        style="height: 100%"
      >
        <FillLateReport @onStepFinished="setCurrentStep(CheckStep.TaskContents)" />
      </n-tab-pane>
      <n-tab-pane :name="CheckStep.TaskContents" :tab="CheckStep.TaskContents" style="height: 100%">
        <TaskContents @onStepFinished="goToStep" />
      </n-tab-pane>
      <n-tab-pane :name="CheckStep.TasksWizard" :tab="CheckStep.TasksWizard" style="height: 100%">
        <TasksWizard :startstepid="startStepId" />
      </n-tab-pane>
    </n-tabs>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  margin: 0;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  /*height: 100%*/
  padding:10px;
  border-radius: 8px;
  background:#fff;
}

.page-title-text {
  width: 100%;
  font-size: 24px;
  color: #151740;
}

.page-description-text {
  margin-top: 14px;
  text-align: left;
  display: block;
  align-self: auto;
}

.contents {
  background: #fff;
  padding: 10px;
  text-align: center;
}
</style>
