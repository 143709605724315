<script lang="ts" setup>
    import { ref } from 'vue';

    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const error = ref<boolean>(false);
    const checkbox = ref<'Yes' | 'No'>(props.modelValue?.toString() as 'Yes' | 'No' || 'Yes');
    const emit = defineEmits(["onValueChange"]);

    const onValueChange = () => {
        emit('onValueChange', checkbox.value, checkbox.value == "Yes" ? "invalid" : 'ok')
    }

    const getRadioButtonColor = () => {
        switch (checkbox.value) {
            case 'Yes':
                return '#800000'
            case 'No':
                return '#008000'
            default:
            return
        }
    }
    onValueChange();

</script>

<template>
    <el-radio-group
            v-model="checkbox"
            :fill="getRadioButtonColor()"
            size="large"
            style="justify-content: center"
            @change="onValueChange"
      >
        <el-radio-button :value="'Yes'" label="Yes" />
        <el-radio-button :value="'No'" label="No" />
      </el-radio-group>
</template>

<style scoped>

</style>