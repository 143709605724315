<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElNotification } from 'element-plus'
import { AuthenticationService } from '@/services/AuthenticationService'
import type { AuthenticationResult } from '@/interfaces/AuthenticationResult'
import { inject } from 'vue'

const router = useRouter()
const worker = inject('worker') as Worker
const email = ref('')
const password = ref('')
const loading = ref(false)

const login = async (): Promise<void> => {
  window.postMessage({ type: 'apptest' }, '*');
  loading.value = true
  let authenticationService = new AuthenticationService()
  let authenticationResult: AuthenticationResult = await authenticationService.login({
    email: email.value,
    password: password.value
  })
  loading.value = false;
  try {
    if (Notification.permission !== "granted") {
        Notification.requestPermission(function (p) {
            if (p === "granted") {
                console.log("Notification permission granted.");
            } else {
                console.log("Notification permission denied.");
            }
        });
    }
  } catch (e) {
    console.log(e);
  }

  if (!authenticationResult.success) {
    ElNotification({
      title: 'Logging failed',
      message: authenticationResult.error,
      type: 'error',
      customClass: 'notification'
    })

    return
  }
  await router.push('/account/dashboard')
}
</script>

<template>
  <div id="background-container">
  <div class="container">
    <el-row class="logo" justify="center">
      <el-col>
        <img height="auto" src="../assets/checkmate-logo.png" width="200px" />
      </el-col>
    </el-row>

    <div style="display: flex; flex-direction: column; width: 100%; align-items: center">
      <div class="left-border" />
      <div class="right-border" />
      <div class="middle">
        <div style="margin-bottom: 4em;">
          <h1>Login</h1>
        </div>

        <div style="margin-bottom: 1.5em;">
          <el-input
            v-model="email"
            clearable
            placeholder="Email"
            resize="none"
            size="large"
            style="width: 100%"
          />
        </div>

        <div style="margin-bottom: 2em;">
          <el-input
            v-model="password"
            placeholder="Password"
            show-password
            size="large"
            style="width: 100%"
            type="password"
          />
        </div>  

        <div style="margin-bottom: 1em;">
          <el-button class="login-button" size="large" @click="login" :loading="loading">Login</el-button>
        </div>

        <div style="margin-bottom: 1em;">
          <el-link
            href="/reset-password"
            style="color: white; text-decoration: underline"
            target="_blank"
            >Forgotten Password?
          </el-link>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<style scoped>
.container {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.logo {
  position: relative;
  bottom: 100px;
  margin-top: 0;
}

.login-button {
  width: 100%;
  background-color: #79b342;
  border-color: #79b342;
  color: black;
  font-size: 1.5em;
}

.left-border {
  position: absolute;
  margin-top: -50px;
  margin-right: 300px;
  width: 50px;
  height: 400px;
  border-left: 2px solid lightgray;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
}

.right-border {
  position: absolute;
  margin-top: -50px;
  margin-left: 300px;
  width: 50px;
  height: 400px;
  border-right: 2px solid lightgray;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
}
.middle {
  position: absolute;
  width: 300px;
  height: 400px;
  text-align: center
}
</style>
