import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import naive from 'naive-ui'

import ElementPlus, { ElNotification } from 'element-plus'
import Vue3Signature from 'vue3-signature'
import 'element-plus/dist/index.css'
import 'vue-scroll-picker/lib/style.css'

import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import Worker from './worker?worker'

import * as Sentry from "@sentry/vue";

const pinia = createPinia()
const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://ef062b59e85a3cb68e112e0772ca9447@o4508065373421568.ingest.de.sentry.io/4508066277556304",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === 'xhr') breadcrumb.data = (hint?.xhr as XMLHttpRequest).response;
    return breadcrumb;
  },
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/app\.checkmate\.software/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});



app.use(Vue3Signature)
app.use(pinia)
app.use(router)
app.use(ElementPlus)
app.use(naive)
//app.use(VueJwtDecode)

const worker = new Worker()
app.provide("worker", worker);

axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response == undefined) {
      if (error.response.status === 401) {
        router.push('/')
        localStorage.clear()
        sessionStorage.clear()
      } else if (error.response.status === 403) {
        router.push('/')
      } else {
        ElNotification({
          title: 'Error',
          message: error.response.data.message,
          type: 'error'
        });
        return Promise.reject(error)
      }
    } else {
      ElNotification({
        title: 'Error',
        message: error.message,
        type: 'error'
      });
      return Promise.reject(error)
    }
  }
)
axios.defaults.headers.common = { Authorization: `bearer ${localStorage.getItem('access_token')}` }

app.mount('#app')
