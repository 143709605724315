<script setup lang="ts">
import { NConfigProvider } from 'naive-ui'
import AudioAlert from './components/AudioAlert.vue';

window.postMessage({ type: 'apptest' }, '*');
window.onmessage = function (e) {
  if (e.data.type === 'apptest') {
    if (e.data.data != undefined) {
      alert('apptest ' + e.data.data);
    }
  }
};
</script>

<template>
  <n-config-provider>
    <n-notification-provider :placement="'top'">
      <RouterView v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="route.name" />
        </transition>
      </RouterView>
    </n-notification-provider>
  </n-config-provider>
  <audio-alert />
</template>

<style scoped>
#background-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
